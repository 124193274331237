/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it


exports.onRouteUpdate = function({ location }) {

    //console.log('location = '+ location.pathname)
    let str = location.pathname
    let n = str.indexOf("article/")
    if(n != 1) {
        // load ads only for the paths /article/xxxxx
        //console.log('not loading ad')
        return null 
    }

    // wrap inside a timeout to make sure react-helmet is done with it's changes (https://github.com/gatsbyjs/gatsby/issues/9139)
    // reactHelmet is using requestAnimationFrame so we should use it too: https://github.com/nfl/react-helmet/blob/5.2.0/src/HelmetUtils.js#L296-L299
    const pushProper = () => {
        //console.log('on route update')
    
        window.loadProperMain();
        window.propertag.cmd.push(function() { window.proper_display('dailyspikes_top'); });
        window.propertag.cmd.push(function() { window.proper_display('dailyspikes_content_1'); });
        window.propertag.cmd.push(function() { window.proper_display('dailyspikes_sticky_1'); });
        window.propertag.cmd.push(function() { window.proper_display('dailyspikes_content_2'); });
        window.propertag.cmd.push(function() { window.proper_display('dailyspikes_content_3'); });
        window.propertag.cmd.push(function() { window.proper_display('dailyspikes_side_1'); });
        window.propertag.cmd.push(function() { window.proper_display('dailyspikes_side_2'); });
    }

    if (`requestAnimationFrame` in window) {
        requestAnimationFrame(() => {
            requestAnimationFrame(pushProper)
        })
    } else {
    // simulate 2 rAF calls
        setTimeout(pushProper, 32)
    }
    
  }
module.exports = [{
      plugin: require('/Users/kmnaing/Sites/dailyspikes_gatsby/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"quality":80,"maxWdith":1800,"withWebp":true,"wrapperStyle":{"width":"100%"},"linkImagesToOriginal":false},
    },{
      plugin: require('/Users/kmnaing/Sites/dailyspikes_gatsby/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-706266-16"},
    },{
      plugin: require('/Users/kmnaing/Sites/dailyspikes_gatsby/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/kmnaing/Sites/dailyspikes_gatsby/gatsby-browser.js'),
      options: {"plugins":[]},
    }]

// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/Users/kmnaing/Sites/dailyspikes_gatsby/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-gallery-js": () => import("/Users/kmnaing/Sites/dailyspikes_gatsby/src/templates/gallery.js" /* webpackChunkName: "component---src-templates-gallery-js" */),
  "component---src-templates-articles-js": () => import("/Users/kmnaing/Sites/dailyspikes_gatsby/src/templates/articles.js" /* webpackChunkName: "component---src-templates-articles-js" */),
  "component---src-templates-article-js": () => import("/Users/kmnaing/Sites/dailyspikes_gatsby/src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-tags-js": () => import("/Users/kmnaing/Sites/dailyspikes_gatsby/src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */),
  "component---src-pages-404-js": () => import("/Users/kmnaing/Sites/dailyspikes_gatsby/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cat-personality-js": () => import("/Users/kmnaing/Sites/dailyspikes_gatsby/src/pages/cat-personality.js" /* webpackChunkName: "component---src-pages-cat-personality-js" */),
  "component---src-pages-elements-js": () => import("/Users/kmnaing/Sites/dailyspikes_gatsby/src/pages/elements.js" /* webpackChunkName: "component---src-pages-elements-js" */),
  "component---src-pages-generic-js": () => import("/Users/kmnaing/Sites/dailyspikes_gatsby/src/pages/generic.js" /* webpackChunkName: "component---src-pages-generic-js" */),
  "component---src-pages-index-js": () => import("/Users/kmnaing/Sites/dailyspikes_gatsby/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-landing-js": () => import("/Users/kmnaing/Sites/dailyspikes_gatsby/src/pages/landing.js" /* webpackChunkName: "component---src-pages-landing-js" */),
  "component---src-pages-pure-luck-hard-work-js": () => import("/Users/kmnaing/Sites/dailyspikes_gatsby/src/pages/pure-luck-hard-work.js" /* webpackChunkName: "component---src-pages-pure-luck-hard-work-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/kmnaing/Sites/dailyspikes_gatsby/.cache/data.json")

